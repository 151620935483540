import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { Router} from '@angular/router';


@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  directiveRef?: PerfectScrollbarDirective;
  
  constructor(
    private router: Router, 

  ) { 
   
  }

  checkPermission(route) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let permissions = currentUser.permissions;
    return permissions.includes(route);
  }

  ngOnInit(): void {
    
  }

}
