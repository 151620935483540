// speech-recognition.service.ts
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  private recognition: any;

  constructor(private zone: NgZone) {
    const { webkitSpeechRecognition }: IWindow =(window as unknown) as IWindow;
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
  }

  listen(): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log('speech rec service');
      let finalTranscript = ''; // Used to store the final transcript
  
      this.recognition.onresult = (event: any) => {
        let interimTranscript = '';
  
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
  
        // Resolve with final transcript when available
        if (finalTranscript.length > 0) {
          this.zone.run(() => {
            resolve(finalTranscript);
          });
        }
      };
  
      this.recognition.onerror = (error: any) => {
        this.zone.run(() => {
          reject(error);
        });
      };
  
      this.recognition.start();
    });
  }
  

  stop() {
    this.recognition.stop();
    console.log('speech rec stop');
  }
}


interface IWindow extends Window {
  webkitSpeechRecognition: any;
}
