import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerObject } from 'src/app/_interfaces/cinema/CustomerObject';
import { environment } from 'src/environments/environment';

const API_URL = environment.prismaApiUrl +'/api/dispo/orders';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  currentObject: any;
  isNew: Boolean = false;

  constructor(private http: HttpClient) { }

  all(): Observable<any> {
    return this.http.get(API_URL);
  }

  getAll(page: number, perPage: number, filterCustomer: number, filterOrderType: number, filterCustomerBranch: number, searchString: string, filterStatus: number, selectedTab: number): Observable<any> {
    return this.http.get(API_URL + '?page=' + page + '&per_page=' + perPage + '&filterCustomer=' + filterCustomer + '&filterOrderType=' + filterOrderType + '&filterCustomerBranch=' + filterCustomerBranch + '&filterStatus=' + filterStatus + '&searchString=' + searchString + '&selectedTab=' + selectedTab
    );
  }

  storeClassParams(id:any, object: any): Observable<any> {
    return this.http.post<CustomerObject>(API_URL + '/store-class-params/' + id, {data: object});
  }

  storeDuration(id: any, object: any): Observable<any> {
    return this.http.post<CustomerObject>(API_URL + '/store-duration/' + id, {data: object});
  }


  getFilter(): Observable<any> {
    return this.http.get(API_URL + '/filter');
  }

  getFields(): Observable<any> {
    return this.http.get(API_URL + '/get_fields');
  }

  search(string): Observable<any> {
    return this.http.get(API_URL + '/search/' + string);
  }

  export(): Observable<any> {
    return this.http.get(API_URL + '/export',{responseType: 'blob'});
  }

  send(): Observable<any> {
    return this.http.get(API_URL + '/send');
  }

  store(object: any): Observable<any> {
    return this.http.post<CustomerObject>(API_URL, {data: object});
  }

  copy(object: any): Observable<any> {
    return this.http.post<CustomerObject>(API_URL + '/copy', {data: object});
  }

  dispose(id: any): Observable<any> {
    return this.http.get(API_URL + '/dispose/' + id);
  }

  archive(id: any): Observable<any> {
    return this.http.get(API_URL + '/archive/' + id);
  }

  delete(object: any): Observable<any> {
    return this.http.delete(API_URL + '/' + object.id);
  }

  cacheOrders(): Observable<any> {
    return this.http.get(API_URL + '/cache-orders');
  }

  show(id: any): Observable<any> {
    this.currentObject = this.http.get(API_URL + '/' + id);
    return this.currentObject;
  }

  undispose(id: any): Observable<any> {
    this.currentObject = this.http.get(API_URL + '/undispose/' + id);
    return this.currentObject;
  }

  update(object: any): Observable<any> {
    return this.http.put<CustomerObject>(API_URL + '/' + object.id, {data: object});
  }

  genPDF(object: any, columns: any, header: any): Observable<any> {
    console.log(columns);
    return this.http.post(API_URL + '/gen-pdf', {data: object.data, columns: columns, header: header}, {responseType: 'blob'});
  }

  genBillingPDF(object: any, billing: any, options: any): Observable<any> {
    return this.http.post(API_URL + '/gen-billing-pdf', {data: object.data, billing: billing, options: options}, {responseType: 'blob'});
  }

  getCurrentObject() {
    return this.currentObject;
  }


  setIsNew(value: any) {
    this.isNew = value;
  }

  getIsNew() {
    return this.isNew;
  }
}
