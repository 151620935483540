<div class="rental-manager-application">
    <div class="app-content content">
    
    
        <div class="content-right">
    
            <div class="content-overlay"></div>
            <div class="content-wrapper">
              <div class="content-body">
                <!-- email app overlay -->
                <div class="email-app-area">
                  <div class="email-app-list-wrapper">
                    <div class="email-app-list">
    
                      <!-- H E A D E R   S T A R T-->
                      <div class="email-action">
                        <!-- action left start here -->
                        <div class="action-left d-flex align-items-center">
        
                          <div class="custom-control custom-checkbox selectAll mr-50">
                            <input type="checkbox" class="custom-control-input" id="selectAll">
                            <label class="custom-control-label" for="selectAll"></label>
                          </div>
                          <!-- delete unread dropdown -->
                          <ul class="list-inline m-0 d-flex">
                            <li class="list-inline-item mail-delete" (click)="showComposeSidebar($event)">
                              <button type="button" class="btn btn-icon action-icon">
                                <i class="ficon feather ft-plus"></i>
                              </button>
                            </li>
                          </ul>
                        </div>
                        <!-- action left end here -->
        
                        <!-- action right start here -->
                        <div class="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                          <!-- search bar  -->
                          <div class="email-fixed-search flex-grow-1">
                            <div class="sidebar-toggle d-block d-lg-none">
                              <i class="ficon feather ft-align-justify"></i>
                            </div>
                            <fieldset class="form-group position-relative has-icon-left m-0">
                              <input type="text" class="form-control" id="email-search" 
                                placeholder="Karte suchen">
                              <div class="form-control-position">
                                <i class="ficon feather ft-search"></i>
                              </div>
                            </fieldset>
                          </div>
                          <!-- pagination and page count -->
                          <span class="d-none d-sm-block">1-10 of 653</span>
                          <button class="btn btn-icon email-pagination-prev d-none d-sm-block">
                            <i class="ficon feather ft-chevron-left"></i>
                          </button>
                          <button class="btn btn-icon email-pagination-next d-none d-sm-block">
                            <i class="ficon feather ft-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                      <!-- H E A D E R   E N D-->
        
                      <!-- L I S T   S T A R T-->
                      <div class="email-user-list list-group"  [perfectScrollbar]="config">
                        <table class="table table-auto">
                          <tr class="bg-gray-500 text-white text-xl font-bold">
                            <td></td>
                            <td>Bild</td>
                            <td>Bezeichnung</td>
                            <td>Key</td>
                            <td>Preis</td>
                            <td>Aktnummer</td>
                            <td>max. Verkauf</td>
                          </tr>  

                          <tr class="cursor-pointer media-body" *ngFor="let contact of ticketList; let i = index" (click)="showEmail($event)">
                                <td class="user-action w-10">
                                    <div class="checkbox-con mr-25">
                
                                        <div class="custom-control custom-checkbox">
                                        <input type="checkbox" [(ngModel)]="contact.isSelected" class="custom-control-input"
                                            id="checkboxsmall{{i}}">
                                        <label class="custom-control-label" for="checkboxsmall{{i}}"></label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-20 h-10 bg-gray-200"></div>
                                </td>
                                <td>{{contact.bezeichnung}}</td>
                                <td>{{contact.key}}</td>
                                <td>{{contact.preis | number: "1.2-2"}}€</td>
                                <td>{{contact.aktnummer}}</td>
                                <td>{{contact.maxverkauf}}</td>

                            </tr>
                        </table>
                        <!-- email user list end -->
        
                        <!-- no result when nothing to show on list -->
                        <div class="no-results">
                          <i class="ficon feather ft-info font-large-2"></i>
                          <h5>No Items Found</h5>
                        </div>
                      </div>
                      <!-- L I S T   E N D-->
                    </div>
                  </div>
                  <!--/ Email list Area -->
        
                  <!-- Detailed Email View -->
                  <div class="email-app-details" id="app-details">
                    <!-- email detail view header -->
                    <div class="email-detail-header">
                      <div class="email-header-left d-flex align-items-center mb-1">
                        <span class="go-back mr-50">
                          <i class="ficon feather ft-chevron-left font-medium-4 align-middle" (click)="showEmail($event)"></i>
                        </span>
                        <h5 class="email-detail-title font-weight-normal mb-0">
                          Detailansicht
                        </h5>
                      </div>
                      <div class="email-header-right mb-1 ml-2 pl-1">
                        <ul class="list-inline m-0">
    
                        </ul>
                      </div>
                    </div>
    
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>  
    </div>      
