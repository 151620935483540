<div class="rental-manager-application">
    <div class="app-content content">
        <div class="sidebar-left" id="sidebar-left">
          <div class="sidebar">
            <div class="sidebar-content email-app-sidebar d-flex">
              <!-- sidebar close icon -->
              <span class="sidebar-close-icon">
                <i class="ficon feather ft-x"></i>
              </span>
              <!-- sidebar close icon -->
              <div class="email-app-menu">
                <div class=" bg-white text-center text-2xl" style="border-bottom: 1px solid #ddd; padding: 18px 0px; margin-top: 4px">Stammdaten</div>
                <div class="" fxFlex="auto" [perfectScrollbar]="config">
                    <ul class="media-list">
                        <li *ngIf="checkPermission('menu.settings')" class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./settings-manager']">Einstellungen</li>
                        <li *ngIf="checkPermission('menu.users')" class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./user-manager']">Benutzer</li>
                        <li *ngIf="checkPermission('menu.roles')" class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./role-manager']" >Rollen & Rechte</li>
                        <li class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./skill-manager']">Klassifizierung</li>
                        <li class="p-1 border-b-2 cursor-pointer  bg-white" [routerLink]="['./good-manager']">Verzeichnis</li>
                        <li class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./calc-manager']">Kalkulation</li>
                        <li class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./text-presets']">Textvorlagen</li>
                        <li class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./document-presets']">Stammdokumente</li>
                        <li class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./media-manager']">Media-Manager</li>
                        <li *ngIf="checkPermission('menu.storage')" class="p-1 border-b-2 cursor-pointer  bg-white" [routerLink]="['./skill-manager']">Lager</li>
                        <li *ngIf="checkPermission('menu.mobile_app')"class="p-1 border-b-2 cursor-pointer bg-white" [routerLink]="['./skill-manager']">Mobile - APP</li>
                    </ul>    
                </div>
              </div>
            </div>

    
        </div>
        </div>
    
        <div class="content-right">
            <div class="content-header row">
            </div>
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <div class="content-body"  [perfectScrollbar]="config">
                    <router-outlet></router-outlet>
                </div>
            </div>
          </div>
    </div>  
    </div>      