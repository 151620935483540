<!-- main app container -->
<ngx-loading-bar [includeSpinner]='false' height='3px' color="#FF4961"></ngx-loading-bar>
<app-alert></app-alert>
<!--div class="microphone-icon" [class.visible]="isCtrlPressed">
    <div class="pulsating-circle"></div>
    <i class="la la-microphone"></i> 
  </div-->

  <!--div class="visual-indicator" [class.visible]="isAltPressed">
    <div class="pulsating-quad"></div>
    <i class="la la-android"></i>
  </div-->
<router-outlet *ngIf="showContent"></router-outlet>