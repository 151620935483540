<div class="rental-manager-application">
    <div class="app-content content">
    
    
        <div class="content-right">
    
            <div class="content-overlay"></div>
            <div class="content-wrapper">
              <div class="content-body">
                <!-- email app overlay -->
                <div class="email-app-area">
                  <div class="email-app-list-wrapper">
                    <div class="email-app-list">
    
                      <!-- H E A D E R   S T A R T-->
                      <div class="email-action">
                        <!-- action left start here -->
                        <div class="action-left d-flex align-items-center">
        
                          
                          <!-- delete unread dropdown -->
                          <ul class="list-inline m-0 d-flex">
                            <h1 style="padding-right: 0px;" class="" class="mr-1">Waren |</h1>
                           
                          </ul>
                        </div>
                        <!-- action left end here -->
        
                        <!-- action right start here -->
                        <div class="action-right d-flex flex-grow-1 align-items-left justify-end">
                          <!-- search bar  -->

                        <input class="border-2 mr-1 w-100" style="padding-left: 10px;"/>  
                        <button type="button" class="btn btn-icon action-icon d-sm-block">
                            Suchen
                        </button>
                        <button type="button" class="w-20 ml-1 btn btn-icon action-icon d-sm-block bg-warning text-white" (click)="addGood()">
                            NEU
                        </button>
                        <button type="button" class="w-20 ml-1 btn btn-icon action-icon d-sm-block">
                            <i class="ficon feather ft-printer"></i>
                        </button>      
                        <button type="button" class="w-20 ml-1 btn btn-icon action-icon d-sm-block">
                            <i class="ficon feather ft-mail"></i>
                        </button>         

                        <!--button type="button" title="Tagesbericht anlegen" class="w-50 btn btn-icon email-pagination-prev d-none d-sm-block">
                            Tagesbericht - 29.09.2022 erstellen
                        </button-->
                      
                          <button class="btn btn-icon email-pagination-prev d-none d-sm-block">
                            <i class="ficon feather ft-chevron-left"></i>
                          </button>
                          <button class="btn btn-icon email-pagination-next d-none d-sm-block">
                            <i class="ficon feather ft-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                      <!-- H E A D E R   E N D-->
        
                      <!-- L I S T   S T A R T-->
                      <div class="email-user-list list-group"  [perfectScrollbar]="configPS">
                        
                        <table class="table table-striped table-hover">
                          <tr class="bold">
                            <td>ID</td>
                            <td>Image</td>
                            <td>Titel</td>
                            <td>Categorie</td>
                            <td>Hersteller</td>
                            <td>Verkaufspreis</td>
                            <td>Einkaufspreis</td>
                            <td>Rabattpreis</td>
                            <td>Lager</td>
                            <td>Aktiv</td>
                          </tr>
                          <tr *ngFor="let good of goodsList; let i = index"  (click)="gotoGoodDetails('movie-base', good.id);" class="cursor-pointer">
                            <td>{{good.id}}</td>
                            <td><div class="w-8 h-8 bg-black"></div></td>
                            <td>{{good.title}}</td>
                            <td>{{good.pos_category_id}}</td>
                            <td>{{good.supplier_id}}</td>
                            <td>{{good.sales_price}}</td>
                            <td>{{good.purchasing_price}}</td>
                            <td>{{good.discount_price}}</td>
                            <td>{{good.stock}}</td>
                            <td>{{good.is_active}}</td>   
                          </tr>
                         
                        </table>
                        <router-outlet (deactivate)="onRouterOutletActivate($event)"></router-outlet>
        
                        
                      </div>
                      <!-- L I S T   E N D-->
                    </div>
                  </div>
                  <!--/ Email list Area -->
        

                </div>
              </div>
            </div>
          </div>
    </div>  
    </div>      


