import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-backup-manager',
  templateUrl: './backup-manager.component.html',
  styleUrls: ['./backup-manager.component.css']
})
export class BackupManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
