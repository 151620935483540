
<input style="margin-top: 10px;" [(ngModel)]="description" placeholder="Notiz" class="form-control"/>
<button class="btn btn-success form-control" (click)="storeNote()">speichern</button>
<table class="table table-striped table-hover" style="border-top: 0px !important; margin-top: 0px;">
  <tbody>
  <tr class="cursor-pointer" style="width: 100% !important" *ngFor="let note of noteList">
    <td style="padding: 5px; width: 40px;">
      <div class="circle">
        <span class="font-bold text-white">{{note.user.short}}</span>
      </div>
      <!--div class="avatar">
        <img class="rounded-circle" src="../../../../assets/images/portrait/small/silke.jpg" alt="Generic placeholder image"/>
      </div-->
    </td>
    <td style=" width: 160px;"> {{note.user.name}}</td>
    <td style=" width: 200px;">{{note.created_at | date: 'dd.MM.yyyy, HH:mm'}}Uhr</td>
    <td>{{note.description}}</td>
  </tr>
  </tbody>
</table>
