import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MovieObject } from 'src/app/_interfaces/cinema/MovieObject';
import { environment } from 'src/environments/environment';

const API_URL = environment.prismaApiUrl +'/api/kino/movies';
const API_MOVIEDB = '4b7dc1e0fdf2ba7f713734a225661dfd';
//https://api.themoviedb.org/3/search/movie?api_key=<<api_key>>&language=en-US&page=1&include_adult=false

@Injectable({
  providedIn: 'root'
})
export class MovieService {
  currentObject: any;
  refreshed: any;
  isNew: Boolean = false;

  constructor(private http: HttpClient) { }

  /*
  searchMovie(query): Observable<any> {
    return this.http.get('https://api.themoviedb.org/3/search/movie?api_key=4b7dc1e0fdf2ba7f713734a225661dfd&language=de-DE&page=1&include_adult=true&query=' + query);
  }
  */

  getAll(): Observable<any> {
    return this.http.get(API_URL);
  }

  store(object: any): Observable<any> {
    return this.http.post<MovieObject>(API_URL, {data: object});
  }

  delete(object: any): Observable<any> {
    return this.http.delete(API_URL + '/' + object.id);
  }

  show(id: any): Observable<any> {
    this.currentObject = this.http.get(API_URL + '/' + id);
    if(this.currentObject == null) {this.isNew = true};
    return this.currentObject;
  }

  update(object: any): Observable<any> {
    return this.http.put<MovieObject>(API_URL + '/' + object.id, {data: object});
  }

  genPDF(): Observable<any> {
    return this.http.get(API_URL + '/gen-pdf', {responseType: 'arraybuffer'});
  }

  

  getCurrentObject() {
    return this.currentObject;
  }

  setRefreshStatus(value: any) {
    this.refreshed = value;
  }

  getRefreshStatus() {
    return this.refreshed;
  }

  setIsNew(value: any) {
    this.isNew = value;
  }

  getIsNew() {
    return this.isNew;
  }

}
