import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  currentObject: any;
  cid: any;
  private _moduleId: any;
  private _moduleItemId: any;

  constructor(private http: HttpClient) {
    this.loadInitialValues();
  }

  set module_id(value: any) {
    this._moduleId = value;
    localStorage.setItem('module_id', JSON.stringify(value));
  }

  get module_id(): any {
    return this._moduleId;
  }

  set module_item_id(value: any) {
    this._moduleItemId = value;
    localStorage.setItem('module_item_id', JSON.stringify(value));
  }

  get module_item_id(): any {
    return this._moduleItemId;
  }

  private loadInitialValues() {
    const storedModuleId = localStorage.getItem('module_id');
    const storedModuleItemId = localStorage.getItem('module_item_id');

    this._moduleId = storedModuleId ? JSON.parse(storedModuleId) : null;
    this._moduleItemId = storedModuleItemId ? JSON.parse(storedModuleItemId) : null;
  }
}
