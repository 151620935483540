import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MovieService } from 'src/app/_services/cinema/movie.service';
import { GoodService } from 'src/app/_services/cinema/good.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.css']
})
export class GoodsComponent implements OnInit {

  public configPS: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  directiveRef?: PerfectScrollbarDirective;
  closeResult: string = '';
  goodsList: any;
  rentalList: any;
  //@ViewChild(PerfectScrollbarDirective, { static: true })

   /**
 * Constructor
 * @param Renderer2                 renderer
 */
    constructor(
      private renderer: Renderer2,
      private modalService: NgbModal,
      private movieSerivce: MovieService,
      private goodService: GoodService,
      private router: Router,
      private route: ActivatedRoute
    ) {}

 
  ngOnInit(): void {
    this.fetchData();
  }

  addGood() {
    this.router.navigate(['./' + 'neu' + '/goods-base'],{ relativeTo: this.route });
  }

  convertToDate(unix) {
    const date = new Date(unix*1000);
    return date;
  }

  gotoGoodDetails(url, id) {   
    this.router.navigate(['./' + id + '/goods-base'],{ relativeTo: this.route });
  }

  onRouterOutletActivate(event) {
    this.fetchData();
   }

  fetchData() {
    this.goodService.getAll().subscribe(
      data => {
        this.goodsList = data;
      },
      err => {
        this.goodsList = JSON.parse(err.error).message;
      }
    );
  }
  

}
