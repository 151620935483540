import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-ticket-manager',
  templateUrl: './ticket-manager.component.html',
  styleUrls: ['./ticket-manager.component.css']
})
export class TicketManagerComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild(PerfectScrollbarDirective, { static: true })

  isHidden = false;
  isShown = true;
  isSelected: boolean;
  isCollapsed = false;
  selectAll = false;
  selected: [];
  blured = false;
  focused = false;

  ticketList = [
   
    {"karten_id":"73","konto":"1","key":"66","preis":"8","bezeichnung":"2: Blau Kinder","aktnummer":"295","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau.png"},
    {"karten_id":"69","konto":"1","key":"36","preis":"3.5","bezeichnung":"SKW 2019","aktnummer":"7152","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"72","konto":"1","key":"67","preis":"9","bezeichnung":"2: Blau ermäßigt","aktnummer":"551","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau.png"},
    {"karten_id":"25","konto":"1","key":"81","preis":"9","bezeichnung":"1 Blau Normal","aktnummer":"3213","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau.png"},
    {"karten_id":"31","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois_obenunten.png"},
    {"karten_id":"26","konto":"1","key":"121","preis":"8","bezeichnung":"1 Blau ermäßigt","aktnummer":"1986","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau_obenunten.png"},
    {"karten_id":"27","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois_obenunten.png"},
    {"karten_id":"86","konto":"1","key":"117","preis":"9","bezeichnung":"2 Weiß Normal","aktnummer":"950","vorstellung_id":"0","maxverkauf":"0","image":"weiss.png"},
    {"karten_id":"28","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"moosgruen_obenunten.png"},
    {"karten_id":"29","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"mooesguen.png"},
    {"karten_id":"30","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau.png"},
    {"karten_id":"32","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois_obenunten.png"},
    {"karten_id":"35","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":""},
    {"karten_id":"78","konto":"1","key":"77","preis":"8","bezeichnung":"2 Grün Ermäßigt","aktnummer":"419","vorstellung_id":"0","maxverkauf":"0","image":"mooesguen.png"},
    {"karten_id":"34","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"braun.png"},
    {"karten_id":"71","konto":"1","key":"69","preis":"10","bezeichnung":"2: Blau normal","aktnummer":"915","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau.png"},
    {"karten_id":"33","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"braun.png"},
    {"karten_id":"70","konto":"1","key":"98","preis":"6","bezeichnung":"SV 6 €","aktnummer":"990","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"36","konto":"1","key":"47","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"braun.png"},
    {"karten_id":"38","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb_obenunten.png"},
    {"karten_id":"39","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb_obenunten.png"},
    {"karten_id":"40","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"977","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb_obenunten.png"},
    {"karten_id":"41","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb_querschmal.png"},
    {"karten_id":"42","konto":"1","key":"238","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":""},
    {"karten_id":"43","konto":"1","key":"99","preis":"9","bezeichnung":"1 Grün  Normalpreis","aktnummer":"3369","vorstellung_id":"0","maxverkauf":"0","image":"mooesguen.png"},
    {"karten_id":"44","konto":"1","key":"62","preis":"8","bezeichnung":"1 Grün Ermäßigt","aktnummer":"2140","vorstellung_id":"0","maxverkauf":"0","image":"moosgruen_obenunten.png"},
    {"karten_id":"45","konto":"1","key":"242","preis":"7","bezeichnung":"1 Grün Kinder","aktnummer":"1501","vorstellung_id":"0","maxverkauf":"0","image":"moosgruen_querschmal.png"},
    {"karten_id":"46","konto":"1","key":"176","preis":"9","bezeichnung":"1 Rot Normal","aktnummer":"2435","vorstellung_id":"0","maxverkauf":"0","image":"eosin.png"},
    {"karten_id":"47","konto":"1","key":"94","preis":"8","bezeichnung":"1 Rot Ermäßigt","aktnummer":"1486","vorstellung_id":"0","maxverkauf":"0","image":"eosin_obenunten.png"},
    {"karten_id":"48","konto":"1","key":"232","preis":"8","bezeichnung":"1 Weiß Normal","aktnummer":"3992","vorstellung_id":"0","maxverkauf":"0","image":"weiss.png"},
    {"karten_id":"49","konto":"1","key":"41","preis":"6","bezeichnung":"1 Weiß Kinder","aktnummer":"784","vorstellung_id":"0","maxverkauf":"0","image":"weiss_obenunten.png"},
    {"karten_id":"50","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"braun.png"},
    {"karten_id":"68","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"53","konto":"1","key":"239","preis":"8","bezeichnung":"1 Pink Kinder","aktnummer":"975","vorstellung_id":"0","maxverkauf":"0","image":"rosa.png"},
    {"karten_id":"52","konto":"1","key":"248","preis":"9","bezeichnung":"1 Pink Ermäßigt","aktnummer":"952","vorstellung_id":"0","maxverkauf":"0","image":"rosa.png"},
    {"karten_id":"51","konto":"1","key":"106","preis":"10","bezeichnung":"1 Pink Normal","aktnummer":"1501","vorstellung_id":"0","maxverkauf":"0","image":"rosa.png"},
    {"karten_id":"55","konto":"1","key":"116","preis":"7","bezeichnung":"1 Orange Kinder","aktnummer":"1781","vorstellung_id":"0","maxverkauf":"0","image":"mandarin_obenunten.png"},
    {"karten_id":"54","konto":"1","key":"102","preis":"8","bezeichnung":"1 Orange Ermäßigt","aktnummer":"2414","vorstellung_id":"0","maxverkauf":"0","image":"mandarin_obenunten.png"},
    {"karten_id":"56","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"999","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"60","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"59","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":""},
    {"karten_id":"58","konto":"1","key":"119","preis":"4","bezeichnung":"SV 4 €","aktnummer":"907","vorstellung_id":"0","maxverkauf":"0","image":"mandarin.png"},
    {"karten_id":"57","konto":"1","key":"118","preis":"7","bezeichnung":"1 Weiß Ermäßigt","aktnummer":"2260","vorstellung_id":"0","maxverkauf":"0","image":"weiss_obenunten.png"},
    {"karten_id":"75","konto":"1","key":"70","preis":"8","bezeichnung":"2 Pink Ermäßigt","aktnummer":"151","vorstellung_id":"0","maxverkauf":"0","image":"rosa.png"},
    {"karten_id":"62","konto":"1","key":"84","preis":"9","bezeichnung":"1 Gelb Normal","aktnummer":"1492","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"61","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"braun.png"},
    {"karten_id":"65","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"64","konto":"1","key":"120","preis":"5","bezeichnung":"SV 5 €","aktnummer":"9880","vorstellung_id":"0","maxverkauf":"0","image":"chamois_obenunten.png"},
    {"karten_id":"63","konto":"1","key":"60","preis":"8","bezeichnung":"1 Gelb Ermäßigt","aktnummer":"1739","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb_obenunten.png"},
    {"karten_id":"67","konto":"1","key":"246","preis":"7","bezeichnung":"1 Türkis Kinder","aktnummer":"955","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau_obenunten.png"},
    {"karten_id":"66","konto":"1","key":"0","preis":"0","bezeichnung":"","aktnummer":"0","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"77","konto":"1","key":"75","preis":"9","bezeichnung":"2 Grün Normal","aktnummer":"871","vorstellung_id":"0","maxverkauf":"0","image":"mooesguen.png"},
    {"karten_id":"76","konto":"1","key":"73","preis":"7","bezeichnung":"2 Pink Kinder","aktnummer":"255","vorstellung_id":"0","maxverkauf":"0","image":"rosa.png"},
    {"karten_id":"74","konto":"1","key":"68","preis":"9","bezeichnung":"2 Pink Normal","aktnummer":"354","vorstellung_id":"0","maxverkauf":"0","image":"rosa.png"},
    {"karten_id":"79","konto":"1","key":"122","preis":"7","bezeichnung":"2 Grün Kinder","aktnummer":"516","vorstellung_id":"0","maxverkauf":"0","image":"mooesguen.png"},
    {"karten_id":"80","konto":"1","key":"76","preis":"9","bezeichnung":"2: Rot normal","aktnummer":"900","vorstellung_id":"0","maxverkauf":"0","image":"eosin.png"},
    {"karten_id":"81","konto":"1","key":"79","preis":"8","bezeichnung":"2: Rot ermäßigt","aktnummer":"494","vorstellung_id":"0","maxverkauf":"0","image":"eosin.png"},
    {"karten_id":"82","konto":"1","key":"78","preis":"7","bezeichnung":"2: Rot Kinder","aktnummer":"403","vorstellung_id":"0","maxverkauf":"0","image":"eosin.png"},
    {"karten_id":"89","konto":"1","key":"88","preis":"8","bezeichnung":"2 Orange Normal","aktnummer":"970","vorstellung_id":"0","maxverkauf":"0","image":"mandarin.png"},
    {"karten_id":"85","konto":"1","key":"85","preis":"6","bezeichnung":"2 Gelb Kinder","aktnummer":"957","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"83","konto":"1","key":"82","preis":"8","bezeichnung":"2 Gelb Normal","aktnummer":"1001","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"84","konto":"1","key":"80","preis":"7","bezeichnung":"2 Gelb Ermäßigt","aktnummer":"554","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"87","konto":"1","key":"86","preis":"8","bezeichnung":"2 Weiß Ermäßigt","aktnummer":"454","vorstellung_id":"0","maxverkauf":"0","image":"weiss.png"},
    {"karten_id":"88","konto":"1","key":"87","preis":"7","bezeichnung":"2 Weiß Kinder","aktnummer":"423","vorstellung_id":"0","maxverkauf":"0","image":"weiss.png"},
    {"karten_id":"91","konto":"1","key":"220","preis":"6","bezeichnung":"2 Orange Kinder","aktnummer":"644","vorstellung_id":"0","maxverkauf":"0","image":"mandarin.png"},
    {"karten_id":"90","konto":"1","key":"89","preis":"7","bezeichnung":"2 Orange Ermäßigt","aktnummer":"508","vorstellung_id":"0","maxverkauf":"0","image":"mandarin.png"},
    {"karten_id":"92","konto":"1","key":"37","preis":"3.5","bezeichnung":"SKW - 2017","aktnummer":"3967","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"96","konto":"1","key":"101","preis":"7","bezeichnung":"1 Gelb Kinder","aktnummer":"33","vorstellung_id":"0","maxverkauf":"0","image":"kanariengelb.png"},
    {"karten_id":"95","konto":"1","key":"74","preis":"10","bezeichnung":"SV 10 €","aktnummer":"8567","vorstellung_id":"0","maxverkauf":"0","image":"chamois.png"},
    {"karten_id":"94","konto":"1","key":"100","preis":"7","bezeichnung":"1 Rot Kinder","aktnummer":"501","vorstellung_id":"0","maxverkauf":"0","image":"eosin.png"},
    {"karten_id":"93","konto":"1","key":"33","preis":"7","bezeichnung":"1 Blau Kinder","aktnummer":"471","vorstellung_id":"0","maxverkauf":"0","image":"arcticblau.png"}
  ];
    

  /**
 * Constructor
 * @param Renderer2                 renderer
 */


  constructor(
    private renderer: Renderer2
  ) { 
      
  }

  ngOnInit(): void {
  }

   /**
   * Open Media-body
   *
   * @param event   Mail Read
   */
    showEmail(event) {
      const toggleIcon = document.getElementById('app-details');
      if (event.currentTarget.className === 'media-body') {
        this.renderer.addClass(toggleIcon, 'show');
      } else if (event.currentTarget.className === 'ficon feather ft-chevron-left font-medium-4 align-middle') {
        this.renderer.removeClass(toggleIcon, 'show');
      }
    }
  
  
    showComposeSidebar(event) {
      const toggleIcon = document.getElementById('compose-sidebar');
      const toggleSidebar = document.getElementById('sidebar-left');
      const toggleOverlay = document.getElementById('app-content-overlay');
      
      if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn') {
        this.renderer.addClass(toggleIcon, 'show');
       this.renderer.removeClass(toggleSidebar, 'show');
        this.renderer.addClass(toggleOverlay, 'show');
      } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
        this.renderer.removeClass(toggleIcon, 'show');
       }
    }
  
    showCompose(event) {
      const toggleIcon = document.getElementById('compose-sidebar');
      const toggleOverlay = document.getElementById('app-content-overlay');
      if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
        this.renderer.removeClass(toggleIcon, 'show');
        this.renderer.removeClass(toggleOverlay, 'show');
      }
    }

}
