import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserObject } from 'src/app/_interfaces/cinema/UserObject'

const API_URL = environment.prismaApiUrl +'/api/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  currentObject: any;
  refreshed: any;
  isNew: Boolean = false;
  private _settings = new BehaviorSubject<any>(null);
  public settings$ = this._settings.asObservable();

  constructor(private http: HttpClient) { 
    const storedSettings = localStorage.getItem('settings');
    if (storedSettings) {
      this._settings.next(JSON.parse(storedSettings));
    }
  }

  getAll(): Observable<any> {
    return this.http.get(API_URL);
  }

  getPublicSetting(): Observable<any> {
    return this.http.get(API_URL + '/public');
  }

  initUsableSetting(): Observable<any> {
    let data =  this.http.get(API_URL + '/get_usable_settings');
    data.subscribe(settings => {
      this._settings.next(settings);
      localStorage.setItem('settings', JSON.stringify(settings));
    });

    console.log('init settings:' + this._settings);
   
    return this._settings;
  }

  getThemeSettings(): Observable<any> {
    return this.http.get(API_URL + '/theme_settings');
  }
 
  show(id: any): Observable<any> {
    this.currentObject = this.http.get(API_URL + '/' + id);
    return this.currentObject;
  }

  update(object: any): Observable<any> {
    let data =  this.http.put<UserObject>(API_URL + '/' + object.id, {data: object});
    
    data.subscribe(settings => {
      this._settings.next(settings);
      localStorage.setItem('settings', JSON.stringify(settings));
    });
    
    return data;
  }

  getCurrentObject() {
    return this.currentObject;
  }

  public updateSettings(settings: any) {
    this._settings.next(settings);
  }

  private _updateLocalStorage (settings: any) {

  }
}
