// speech-synthesis.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpeechSynthesisService {
  private synth = window.speechSynthesis;

  speak(text: string, lang: string = 'de-DE') {  // Standardmäßig auf Deutsch gesetzt
    if (this.synth.speaking) {
      console.error('SpeechSynthesis is already speaking...');
      return;
    }

    if (text !== '') {
      let utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = lang;  // Setze die Sprache
      this.synth.speak(utterance);
    }
  }
}
