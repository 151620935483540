import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { AlertService } from '../_services/alert.service';
import { email } from 'ngx-custom-validators/src/app/email/validator';
import { SettingService } from '../_services/setting.service';
import { SharedService } from '../_services/shared/shared.service';
import { environment } from 'src/environments/environment';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = true;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  apiUrl = environment.prismaApiUrl;

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  setting: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public  authService: AuthService,
    private renderer: Renderer2,
    private tokenStorage: TokenStorageService,
    private settingService: SettingService,
    private sharedService: SharedService
  ) {
    /*
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
    */

  }

  ngOnInit() {
    this.loading = true;
  
    // Initialisiere das Formular vor dem Laden der Einstellungen, da dies unabhängig ist
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
  
    // Lade Einstellungen und führe darauf aufbauende Logik im Erfolgsfall aus
    this.settingService.getPublicSetting().subscribe(
      data => {
        this.setting = data;
        this.loading = false;
  
        // Prüfe, ob der Benutzer eingeloggt ist
        if (this.tokenStorage.getToken()) {
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
        }
  
        // Entscheide, welche Seite basierend auf dem Zustand geladen werden soll
        if (localStorage.getItem('remember')) {
          this.renderer.removeClass(document.body, 'bg-full-screen-image');
          localStorage.removeItem('currentLayoutStyle');
          this.navigateToStartPage();
        } else if (localStorage.getItem('currentUser')) {
          this.authService.logout();
          this.isPageLoaded = true;
        } else {
          this.isPageLoaded = true;
        }
      },
      err => {
        this.loading = false;
        console.error('Fehler beim Laden der Einstellungen', err);
        // Behandle den Fehler, möglicherweise mit einer Benutzerbenachrichtigung
      }
    );
  }
  
  private navigateToStartPage() {
    // Sicherstellen, dass this.setting.start_page verfügbar ist
    if (this.setting && this.setting.start_page) {
      this.router.navigate([this.setting.start_page]);
    } else {
      console.error('Startseite ist nicht definiert');
      // Alternativ eine Standardseite laden oder Fehlerbehandlung durchführen
    }
  }
  

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const value = {
      email: this.f.email.value,
      password: this.f.password.value
    };
    this.authService.login(value.email, value.password).subscribe(
      res => {
        if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
          localStorage.setItem('remember', 'true');
        } else {
          localStorage.removeItem('remember');
        }
        this.setSettingsInStorage(res);
        this.setUserInStorage(res);
        console.log(res.access_token);
        this.tokenStorage.saveToken(res.access_token);
        //localStorage.removeItem('currentLayoutStyle');
        //let returnUrl = this.setting.start_page;
        //if (this.returnUrl) {
        //  returnUrl = this.returnUrl;
        //}
        //this.router.navigate([returnUrl]);
        this.navigateToStartPage();
      },
      err => {
        this.submitted = false;
        this.alertService.error(err.message);
      }
    );
  }
addCheckbox(event) {
  const toggle = document.getElementById('icheckbox');
  if (event.currentTarget.className === 'icheckbox_square-blue') {
     this.renderer.addClass(toggle, 'checked');
  } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
    this.renderer.removeClass(toggle, 'checked');
  }
}
setUserInStorage(res) {

    if (res.user) {
      localStorage.setItem('currentUser', JSON.stringify(res.user));
      
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
  }


  setSettingsInStorage(res) {
    this.settingService.initUsableSetting();
  }
}
